var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([((_vm.isEditMode && !_vm.isFormsPage && !_vm.isInspectionsPage)
         || (_vm.isEditMode && _vm.isFormsPage && _vm.isFormDraft)
         || (_vm.isEditMode && _vm.isInspectionsPage && _vm.isInspectionCRUDable))?{key:"activator",fn:function(ref){
         var on = ref.on;
         var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":_vm.isFormsPage ? {name: 'form', params: {form_id: _vm.item.id, form: _vm.item}} : '',"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}:((_vm.isDeleteMode && !_vm.isInspectionsPage) || (_vm.isDeleteMode && _vm.isInspectionsPage && _vm.isInspectionCRUDable))?{key:"activator",fn:function(ref){
         var on = ref.on;
         var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}:(_vm.isAddMode)?{key:"activator",fn:function(ref){
         var on = ref.on;
         var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":_vm.$route.name === 'forms' ? {name: 'form-new'} : '',"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])]}}:null],null,true),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-start text-wrap"},[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")]),_c('v-card-text',[_vm._t("default")],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":_vm.btnColor,"disabled":_vm.disabled,"loading":_vm.loading,"text":"","type":"submit"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(" "+_vm._s(_vm.mode)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }